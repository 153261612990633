import { FaWhatsapp } from 'react-icons/fa';

import ItemWidget from './itemWidget';

import styles from './chatWidget.module.scss';

const isTelefone = (assitant: any, telefoneAssistant: any) => (assitant === 'Ligado' && telefoneAssistant !== null
  ? `https://api.whatsapp.com/send?phone=${telefoneAssistant}&text=Olá, tudo bem?`
  : 'https://dehut.app/HXLSBO/botao-whats-hype');

export default function ChatWidget(props: any) {
  const { assitant, telefoneAssistant } = props;

  return (
    <section className={styles.section}>
      <header className={styles.header}>
        <FaWhatsapp className={styles.icon} />
        <section className={styles.text}>
          <h2>Falar agora</h2>
          <span>
            Nossos especialistas de vendas estão sempre disponíveis para te atender.
            <br />
            Nosso suporte ao cliente esta disponível de Segunda a Sexta das 09h às 18h.
          </span>
        </section>
      </header>
      <section className={styles.container}>
        <ItemWidget
          title='Especialista em Vendas'
          text='Compre e tire suas dúvidas com os nossos especialistas'
          icon={<FaWhatsapp />}
          link={isTelefone(assitant, telefoneAssistant)}
        />
        <ItemWidget
          title='Atendimento Hype'
          text='Já é nosso cliente e precisa de ajuda? Fale com a gente'
          icon={<FaWhatsapp />}
          link='https://api.whatsapp.com/send/?phone=5541995150201&text=Olá, tudo bem?'
        />
        <ItemWidget
          title='Seja um parceiro'
          text='Confira nosso Fale Conosco para esse e outros assuntos'
          link='https://hypeempreendimentos.com.br/fale-conosco'
        />
      </section>
    </section>
  );
}
