import Image from 'next/image';
import { useRouter } from 'next/router';

import React, { useEffect, useState } from 'react';
import { CgClose } from 'react-icons/cg';
import { MdKeyboardArrowRight } from 'react-icons/md';

import useMedia from '../../../hooks/useMedia';
import { customLoader } from '../../../utils/customLoaderImage';
import HeadSite from '../../_head';

import routesHeader from './routesHeader';

import style from './header.module.scss';

const parse = require('html-react-parser');

export default function Header({ data }) {
  const [headerMobile, setHeaderMobile] = useState(false);
  const [openDropdown, setOpenDropdown] = useState(null);
  const isMobile = useMedia('(max-width: 768px)');

  const router = useRouter();

  const routesHeaderData = routesHeader(data?.routes);

  const handleRoute = (e) => {
    if (isMobile) setHeaderMobile(false);
    (document.activeElement as HTMLElement).blur();
    router.push(e);
  };

  const toggleDropdown = (label) => {
    setOpenDropdown((prevLabel) => (prevLabel === label ? null : label));
  };

  const handleMenuToggle = () => {
    setHeaderMobile((prev) => !prev);
    setOpenDropdown(null); // Fechar todos os dropdowns ao abrir o menu
  };

  useEffect(() => {
    if (isMobile) {
      setHeaderMobile(false);
    } else {
      setHeaderMobile(true);
    }
  }, [isMobile]);

  useEffect(() => {
    const areaClickClose = document.getElementById('areaClickClose');
    const mainMenu = document.getElementById('main-menu');

    if (headerMobile) {
      if (areaClickClose) areaClickClose.style.display = 'block';
      if (mainMenu) {
        mainMenu.style.display = 'block';
        mainMenu.style.marginLeft = '-20px';
      }
    } else {
      if (areaClickClose) areaClickClose.style.display = 'none';
      if (mainMenu) mainMenu.style.display = 'none';
    }
  }, [headerMobile]);

  return (
    <>
      <HeadSite />
      <header className={style.header}>
        <figure
          role='presentation'
          onClick={() => handleRoute('/')}
          className={style.logo}>
          {data && (
            <Image
              src={data.logo}
              loader={customLoader}
              alt='logo da Hype Empreendimentos'
              width={103}
              height={42}
            />
          )}
          <figcaption>Imagem: logo da Hype Empreendimentos</figcaption>
        </figure>
        <button
          type='button'
          className={style.apVenda}
          onClick={() => handleRoute('/apartamentos-a-venda')}
          aria-label='Ir para Apartamentos à venda'>
          Apartamentos à venda
          {'  '}
          <MdKeyboardArrowRight />
        </button>
        <button
          type='button'
          onClick={handleMenuToggle}
          className={style.toggle}
          aria-label='Abrir menu principal'>
          <span aria-hidden='true' />
          <span aria-hidden='true' />
          <span aria-hidden='true' />
        </button>
        <nav
          id='main-menu'
          aria-label='Menu'
          className={style.mainMenu}>
          <ul>
            <li className={style.close}>
              <button
                type='button'
                aria-label='Fechar Menu'
                onClick={handleMenuToggle}>
                <CgClose />
              </button>
            </li>
            {routesHeaderData
              && routesHeaderData.map((item) => (
                <li
                  key={item.label}
                  className={openDropdown === item.label && isMobile ? 'open' : ''}>
                  <div
                    role='button'
                    tabIndex={isMobile && item.subItems ? -1 : 0}
                    onKeyDown={(event) => event.key === 'Enter' && event.currentTarget.click()}
                    onClick={() => {
                      if (item.subItems && isMobile) {
                        toggleDropdown(item.label);
                      } else {
                        handleRoute(item.href);
                      }
                    }}>
                    {item.label}
                  </div>
                  {item.subItems && (openDropdown === item.label || !isMobile) && (
                    <ul>
                      {item.subItems.map((subItem, index) => (
                        <li key={subItem.label || index}>
                          <div
                            role='button'
                            tabIndex={0}
                            onKeyDown={(event) => event.key === 'Enter' && event.currentTarget.click()}
                            onClick={() => handleRoute(subItem.href)}
                            style={{ borderBottom: '0.1px solid #00bf98' }}>
                            {parse(subItem.label ? subItem.label : '')}
                          </div>
                        </li>
                      ))}
                    </ul>
                  )}
                </li>
              ))}
          </ul>
          <button
            className={style.buttonContact}
            type='button'
            aria-label='Abrir página Fale Conosco'
            onClick={() => handleRoute('/fale-conosco')}>
            {' '}
            Fale conosco
          </button>
          <a
            href='#main-menu-toggle'
            className={style.backdrop}
            tabIndex={-1}
            aria-hidden>
            <span hidden>Menu</span>
          </a>
        </nav>
        <span
          role='presentation'
          className={style.areaClickClose}
          onClick={handleMenuToggle}
          id='areaClickClose'
        />
      </header>
    </>
  );
}
