import { useRouter } from 'next/router';
import loadable from '@loadable/component';

import { useEffect } from 'react';

import AccToolbar from '../components/AccToolbar';
import BottomBar from '../components/BottomBar';
import ConhecaNossasOpcoesButton from '../components/Buttons/ConhecaNossasOpcoesButton';
import WhatsappButton from '../components/Buttons/WhatsappButton';
import LeftFloatingContainer from '../components/Floating/LeftFloatingContainer';
import LytSite from '../components/Layout';
import useMedia from '../hooks/useMedia';

import '../styles/fonts.scss';

function Site({ Component, pageProps }) {
  const router = useRouter();

  const isMobile = useMedia('(max-width: 768px)');

  useEffect(() => {
    import('react-facebook-pixel')
      .then((x) => x.default)
      .then((ReactPixel) => {
        ReactPixel.init(process.env.FACEBOOK_PIXEL_ID);
        ReactPixel.pageView();

        router.events.on('routeChangeComplete', () => {
          ReactPixel.pageView();
        });
      });
  }, [router.events]);

  useEffect(() => {
    const buttonWhatsApp = Array.from(document.getElementsByClassName('bricks--component-popup') as HTMLCollectionOf<HTMLElement>)[0];

    if (typeof window !== 'undefined' && typeof buttonWhatsApp !== 'undefined') {
      buttonWhatsApp.style.marginBottom = '30px';
    }
  }, []);

  const buttonsBottom = () => {
    if (!['/', '/apartamentos-a-venda', '/apartamentos-a-venda/[slug]', '/vem-ser-hype'].includes(router.route)) {
      if (isMobile) {
        return (
          <BottomBar>
            <ConhecaNossasOpcoesButton />
          </BottomBar>
        );
      }

      return (
        <LeftFloatingContainer>
          <ConhecaNossasOpcoesButton />
        </LeftFloatingContainer>
      );
    }

    return null;
  };

  const TagManager = loadable(() => import('../components/MetaTags/tagManagerApp'));
  const ClarityMicrosoft = loadable(() => import('../components/Clarity/ClarityMicrosoft'));

  return (
    <>
      <AccToolbar />
      <LytSite
        headerData={pageProps.headerData}
        footerData={pageProps.footerData}>
        <Component {...pageProps} />
        <ClarityMicrosoft />
        <WhatsappButton />
        {buttonsBottom()}
      </LytSite>
      <TagManager />
    </>
  );
}

export default Site;
