import axios from 'axios';

import { useEffect, useState } from 'react';
import { FaWhatsapp, FaWindowClose } from 'react-icons/fa';

import ChatWidget from '../ChatWidget';

import styles from './whatsappButton.module.scss';

export default function WhatsappButtoN() {
  const [chatOpen, setChatOpen] = useState(false);
  const [assitant, setAssitant] = useState('Desligado');

  const [telefoneAssistant, setTelefoneAssistant] = useState(null);

  useEffect(() => {
    const controller = new AbortController();
    const { signal } = controller;

    async function fetchData() {
      const config = {
        headers: {
          'Cache-Control': 'no-cache',
          'Access-Control-Allow-Origin': '*',
          'Access-Control-Allow-Methods': 'GET, POST, OPTIONS, PUT, PATCH, DELETE',
          'Content-Type': 'application/json;charset=UTF-8',
        },
      };

      const params = {
        collection: 'contatos',
        contact: 'Assistant AI',
        contacts: 'Assistant Leads SDR',
      };

      const options = {
        data: params,
        signal,
        headers: {
          'Content-type': 'application/json;charset=utf-8',
        },
      };

      const conn = axios.create(config);
      const response = await conn?.post('/api/listaContatos', options);

      const docs = response?.data?.data?.docs || [];

      if (docs?.length === 0) return;

      const contatos = docs?.find((doc: any) => doc?.tituloLista === params?.contact)?.contatos || [];

      setTelefoneAssistant(contatos?.find((contato: any) => contato?.nome === params?.contacts)?.telefone);
      setAssitant(contatos?.find((contato: any) => contato?.nome === params?.contacts)?.funcao);
    }

    fetchData();

    return () => {
      controller.abort();
    };
  }, []);

  return (
    <>
      <button
        type='button'
        className={styles.section}
        onClick={() => {
          setChatOpen(!chatOpen);
        }}
        aria-label='Enviar mensagem no WhatsApp da Hype'>
        <div>{chatOpen ? <FaWindowClose /> : <FaWhatsapp />}</div>
      </button>
      {chatOpen ? (
        <ChatWidget
          telefoneAssistant={telefoneAssistant}
          assitant={assitant}
        />
      ) : null}
    </>
  );
}
