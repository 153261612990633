import Image from 'next/image';
import Link from 'next/link';

import React, { useEffect } from 'react';

import { customLoader } from '../../../utils/customLoaderImage';

import style from './footer.module.scss';

export default function Footer({ data }) {
  useEffect(() => {
    const menuItems = document.querySelectorAll<HTMLElement>('#itemMenu');
    const principalItems = ['Home', '+ Institucional', 'Apartamentos a venda', 'Invista na Hype', 'Blog', '+ Hype'];

    menuItems.forEach((item) => {
      if (principalItems.some((principal) => principal === item.textContent)) {
        item.style.fontWeight = 'bolder';
      }
    });
  }, []);

  if (!data) return null;

  const { socialMedia } = data;
  const linkFacebook = socialMedia?.facebook;
  const linkInstagram = socialMedia?.instagram;
  const linkYoutube = socialMedia?.youtube;
  const linkLinkedin = socialMedia?.linkedin;

  return (
    <div className={style.section}>
      <div className={style.block1}>
        <h2>Menu</h2>
        <div>
          {data?.routes.map((link) => (
            <Link
              key={link?.descricao}
              href={link?.item}>
              <a id='itemMenu'>
                {link.descricao.includes('<br />')
                  ? link.descricao.split('<br />').map((text) => (
                    <React.Fragment key={text}>
                      {text}
                      <br />
                    </React.Fragment>
                  ))
                  : link.descricao}
              </a>
            </Link>
          ))}
        </div>
      </div>

      <div className={style.block2}>
        <div>
          <figure>
            <Image
              src={data?.footerIcons.find((icon) => icon.titulo === 'logo_escura')?.url || '/img/logo.webp'}
              loader={customLoader}
              alt='logo da Hype Empreendimentos'
              width={180}
              height={90}
            />
            <figcaption>Imagem: logo da Hype Empreendimentos</figcaption>
          </figure>
        </div>
        <h2> Nos siga nas redes! </h2>
        <div>
          {data?.footerIcons
            .filter((ft) => ['facebook_icon', 'instagram_icon', 'youtube_icon', 'linkedin_icon'].includes(ft.titulo))
            .map((img) => {
              let href = '';

              switch (img.titulo) {
                case 'facebook_icon':
                  href = linkFacebook;
                  break;
                case 'instagram_icon':
                  href = linkInstagram;
                  break;
                case 'youtube_icon':
                  href = linkYoutube;
                  break;
                case 'linkedin_icon':
                  href = linkLinkedin;
                  break;
                default:
                  break;
              }

              return (
                <a
                  key={img.titulo}
                  target='_blank'
                  href={href}
                  rel='noreferrer'>
                  <figure>
                    <Image
                      src={img.url}
                      loader={customLoader}
                      alt={img.titulo}
                      width={30}
                      height={30}
                    />
                    <figcaption>
                      Imagem:
                      {`Ícone do ${img.titulo}`}
                    </figcaption>
                  </figure>
                </a>
              );
            })}
        </div>
      </div>

      <div className={style.block3}>
        <h2>Onde estamos</h2>
        {data?.address.map((end) => (
          <a
            key={end.id}
            target='_blank'
            href={end.googleMaps}
            rel='noreferrer'>
            {`${end.endereco}, ${end.numero}, ${end.complemento && end.complemento} ${end.bairro} - ${end.cidade}/${end.estado}, CEP ${end.cep} ${
              end.telefone && ` | Tel: ${end.telefone}`
            }`}
            {' '}
            <br />
          </a>
        ))}
        <br />
        <h2>Contato</h2>
        {data?.contact.map(
          (contato) => contato.nome === 'Central de Atendimento' && (
          <a
            key={contato.id}
            href={`mailto:${contato.email}`}>
            {contato.email}
            <br />
          </a>
          ),
        )}
        {data?.contact.map(
          (end) => end.telefone && (
          <a
            key={end.id}
            href={`tel:${end.telefone}`}>
            {`${end.telefone} - ${end.nome}`}
            <br />
          </a>
          ),
        )}
      </div>

      <div className={style.block4}>
        <Link
          href='/politica-de-privacidade'
          passHref>
          <h3>
            <span
              onKeyDown={(event) => event.key === 'Enter' && event.currentTarget.click()}
              tabIndex={0}
              role='button'>
              Politica de privacidade
            </span>
          </h3>
        </Link>
        <h4>{`Hype Empreendimentos ${new Date().getFullYear()} ©️ CNPJ: 10.576.135/0001-53. Todos os direitos reservados.`}</h4>
        <p>{data?.copy}</p>
      </div>
    </div>
  );
}
