import { Box } from '@mui/system';

import { FaWhatsapp } from 'react-icons/fa';

import styles from './chatWidget.module.scss';

export default function ItemWidget(props: any) {
  const {
    title, text, icon, link,
  } = props;

  return (
    <Box
      className={styles.item}
      onClick={() => window.open(link, '_blank')}>
      <section className={styles.text}>
        <h3>{title}</h3>
        <span>{text}</span>
      </section>
      {icon ? <FaWhatsapp className={styles.whatsapp} /> : null}
    </Box>
  );
}
